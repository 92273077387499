<template>
  <div class="d-block">
    <template v-if="generalDefined.length > 0">
      <div class="d-block mb-2" v-for="(item, index) in generalDefined" :key="index">
        <v-chip label small text-color="white" color="primary">
          {{ item.title }}
        </v-chip>
      
        <div class="d-flex flex-column" v-for="valItem in item.value" :key="valItem">
          <span class="pl-3 text-body-2">- {{valItem}}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "GeneralProgressItem",
  props: {
    symtomBySide: {
      type: Array,
      default: () => []
    }
  },
  data: () => {
    return {
      generalDefined: []
    }
  },
  created() {
    this.formatData()
    console.log(this.Archetypes)
  },
  methods: {
    formatData() {
      if (this.symtomBySide.length > 0) {
        // loop symtomBySide
        this.symtomBySide.forEach(i => {
          let splitPath = i.path.split("/");
          let rootPath = splitPath.shift();
          let archetype = splitPath.pop();
          if (this.searchExistRootGeneral(rootPath)) {
            this.generalDefined = this.generalDefined.map( geItem => {
              if (geItem.rootPath == rootPath) {
                geItem.value.push(this.getValueArchetype(archetype, i.value));
              }
              return geItem;
            })
          } else {
            let item =  {
              rootPath,
              title: this.findTitleArchetype(rootPath),
              value: [this.getValueArchetype(archetype, i.value)]
            }
            this.generalDefined.push(item);
          }

        })
      }
    },
    findTitleArchetype(archetype) {
      return `${archetype}` in this.Archetypes ? this.Archetypes[archetype].name : ''
    },
    searchExistRootGeneral(name) {
      let findGeneral = this.generalDefined.find(i => i.rootPath == name);
      return (typeof findGeneral != 'undefined');
    },
    getValueArchetype(archetype, value) {
      if (value.includes('|')) {
        var splitValue = value.split("|");
        return archetype == "GC" ? `Note: ${splitValue[0]}` : `${this.findTitleArchetype(archetype)} - ${splitValue[0]}`
       
      } else {
        return archetype == "GC" ? `Note: ${value}` : value
      }
    }
  }
}
</script>

<style>

</style>