<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">IOP</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="renderLineChartIOP">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <apexchart
        type="line"
        width="100%"
        :options="chartOptions"
        :series="series"
      ></apexchart>
    </template>
    <template v-else>
      <div class="d-flex w-100 justify-center align-center" style="height: 100%;">
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    
  </v-container>
</template>

<script>
import moment from "moment";
import apexchart from "vue-apexcharts";
import progressService from "@/services/progress";

export default {
  name: "ChartIOP",
  components: {
    apexchart,
  },
  data: () => ({
    loading: false,
    series: [],
    chartOptions: {
      xaxis: {
        categories: [],
        title: {
          text: "Day",
        },
        show: true,
        labels: {
          formatter: (value) => {
            return value;
          },
        },
        datetimeUTC: false,
      },
      chart: {
        height: 370,
        type: "line",
        dropShadow: {
          enabled: true,
          color: "#000",
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2,
        },
        toolbar: {
          show: false,
        },
      },
      colors: ["#0065FF", "#c8b6ff"],
      dataLabels: {
        enabled: true,
      },
      stroke: {
        width: [4, 4],
        curve: "straight",
        dashArray: [0, 4],
      },
      title: {
        text: "",
        align: "left",
      },
      grid: {
        borderColor: "#e7e7e7",
        row: {
          colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
          opacity: 0.5,
        },
      },
      markers: {
        size: 1,
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: true,
        offsetY: -25,
        offsetX: -5,
      },
    },
  }),
  async created() {
    await this.renderLineChartIOP();
  },
  methods: {
    async renderLineChartIOP() {
      this.loading = true;
      var patientID = this.$route.params.patientId;
      const apiResult = await progressService.getRecordChartIOP(
        patientID,
        1,
        1000
      );
      if (apiResult.error) {
        this.loading = false;
        this.$toast.error(apiResult.message);
        return;
      }
      this.loading = false;
      var valueOD = [];
      var valueOS = [];
      apiResult.items.forEach((item) => {
        valueOS.push(item.os.value);
        valueOD.push(item.od.value);
      });
      this.series = [
        {
          name: "Right",
          data: valueOD,
        },
        {
          name: "Left",
          data: valueOS,
        },
      ];
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          ...this.chartOptions.xaxis,
          categories: apiResult.items.map((item) =>
            moment(item.time).format("MMM Do YY")
          ),
        },
      };
      this.$forceUpdate();
    },
  },
};
</script>