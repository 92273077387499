<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">{{ $t("main.progressIOP.title") }}</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="renderLineChartIOP">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <!-- Render result -->
      <div class="form-table">
        <v-row class="top">
          <v-col cols="2" class="text-center">
            <h3>{{ $t("main.progressIOP.date") }}</h3>
          </v-col>
          <v-col cols="5" class="text-center">
            <h3>{{ $t("main.progressIOP.right") }}</h3>
          </v-col>
          <v-col cols="5" class="text-center">
            <h3>{{ $t("main.progressIOP.left") }}</h3>
          </v-col>
        </v-row>
        <v-row v-if="!items.length">
          <v-col cols="12" class="text-center">
            {{ $t("common.noData") }}
          </v-col>
        </v-row>
        <v-row v-else v-for="(item, index) in items" :key="index">
          <v-col cols="2" class="text-center">
            <span class="text-body-2 font-weight-bold">{{ item.time }}</span>
          </v-col>
          <v-col cols="5" class="text-center">
            {{ item.valueOD }}
          </v-col>
          <v-col cols="5" class="text-center">
            {{ item.valueOS }}
          </v-col>
        </v-row>
      </div>
      <div class="mt-2 pa-1 d-flex justify-end">
        <span class="blue--text" style="cursor: pointer" @click="limit += 7">
          {{ $t("common.viewMore") }}
        </span>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex w-100 justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import progressService from "@/services/progress";

export default {
  name: "VisualAcuity",
  data() {
    return {
      totals: "",
      items: [],
      limit: 7,
      loading: false,
    };
  },
  watch: {
    limit() {
      this.renderLineChartIOP();
    },
  },
  async created() {
    await this.renderLineChartIOP();
  },
  methods: {
    async renderLineChartIOP() {
      this.loading = true;
      var patientID = this.$route.params.patientId;
      const apiResult = await progressService.getRecordChartIOP(
        patientID,
        1,
        this.limit
      );
      if (apiResult.error) {
        this.loading = false;
        this.$toast.error(apiResult.message);
        return;
      }
      this.loading = false;
      this.totals = apiResult.totals;
      this.items = apiResult.items.map((item) => ({
        time: moment(item.time).format("DD/MM/YYYY"),
        valueOD: item.od.value,
        valueOS: item.os.value,
      }));
    },
  },
};
</script>

<style lang="scss">
.form-table {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-right: 0px;
  .row {
    margin: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    margin-bottom: -1px;
    .col {
      border-right: 1px solid rgba(0, 0, 0, 0.6);
    }
  }
}
</style>