<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">{{ $t("main.generalExamination.generalExTitle") }}</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="getGeneralExamProgress">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Render general data -->
    <template v-if="!loading">
      <div class="d-block" style="height: 400px; overflow: auto">
        <template v-if="generalData.length > 0">
          <div class="form-table">
            <v-row class="top">
              <v-col cols="2" class="text-center">
                <h3>{{ $t("main.generalExamination.date") }}</h3>
              </v-col>
              <v-col cols="5" class="text-center">
                <h3>{{ $t("main.generalExamination.right") }}</h3>
              </v-col>
              <v-col cols="5" class="text-center">
                <h3>{{ $t("main.generalExamination.left") }}</h3>
              </v-col>
            </v-row>
            <!-- Loop generalData -->
            <v-row v-for="(item, index) in generalData" :key="index">
              <v-col cols="2" class="text-center">
                <span class="text-body-2 font-weight-bold">{{
                  moment(item.timeExam).format("DD/MM/YYYY")
                }}</span>
              </v-col>
              <v-col cols="5">
                <GeneralProgressItem :symtomBySide="item.genOD" />
              </v-col>
              <v-col cols="5">
                <GeneralProgressItem :symtomBySide="item.genOS" />
              </v-col>
            </v-row>
          </div>
        </template>
        <template v-else>
          <p class="text-center">{{ $t("main.generalExamination.noData") }}</p>
        </template>
      </div>
      <div class="mt-2 pa-1 d-flex justify-end">
        <span class="blue--text" style="cursor: pointer" @click="limit += 5">
          {{ $t("common.viewMore") }}
        </span>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex w-100 justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import _ from "lodash";
import GeneralExamService from "@/services/generalExam";
import GeneralProgressItem from "@/components/Dashboard/components/GeneralProgressItem";
export default {
  name: "GeneralExaminationProgressComponent",
  components: {
    GeneralProgressItem,
  },
  async created() {
    await this.getGeneralExamProgress();
    this.rootPath = this.Archetypes.GNRE.archetypeID;
  },
  data: () => {
    return {
      generalData: [],
      rootPath: null,
      limit: 5,
      loading: false,
    };
  },
  watch: {
    limit() {
      this.getGeneralExamProgress();
    },
  },
  methods: {
    moment,
    async getGeneralExamProgress() {
      let patientID = this.$route.params.patientId || null;
      this.loading = true;
      var result = await GeneralExamService.getGeneralExamProgress(
        patientID,
        1,
        this.limit
      );
      if (result.error) {
        this.loading = false;
        this.$toast.error(result.message);
        return;
      }

      this.loading = false;
      if (result.items.length > 0) {
        let listEpisodeRAndDtaP = _.cloneDeep(
          result.items.filter((i) => i.dataPoints.length > 0)
        );
        let formatData = listEpisodeRAndDtaP.map((i) => {
          i.genOD = _.filter(i.dataPoints, (o) => o.path.includes("/OD")).map(
            (o) => {
              o.path = o.path.replace(/\/GNRE\/|\/OD/g, "");
              return o;
            }
          );
          i.genOS = _.filter(i.dataPoints, (o) => o.path.includes("/OS")).map(
            (o) => {
              o.path = o.path.replace(/\/GNRE\/|\/OS/g, "");
              return o;
            }
          );
          delete i.dataPoints;
          return i;
        });
        this.generalData = formatData;
      }
    },
  },
};
</script>

<style>
</style>