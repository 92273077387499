<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">{{ $t("main.progressPrescription.title") }}</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="renderListMedication">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <!-- Display result -->
      <table>
        <thead>
          <tr style="font-size: 18px">
            <th>{{ $t("main.progressPrescription.date") }}</th>
            <th colspan="2">
              {{ $t("main.progressPrescription.prescription") }}
            </th>
          </tr>
        </thead>
        <tbody v-if="!items.length">
          <tr>
            <td colspan="6">{{ $t("common.noData") }}</td>
          </tr>
        </tbody>
        <tbody v-else v-for="(item, index) in items" :key="index">
          <tr>
            <td :rowspan="item.totalsMedications">
              <span class="text-body-2 font-weight-bold">{{ item.date }}</span>
            </td>
          </tr>
          <tr v-for="(medication, index2) in item.data" :key="index2">
            <td>{{ medication.medicineName }}</td>
            <td v-if="medication.note == null" style="color: #e26d5c">Empty</td>
            <td v-else>{{ medication.note }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2 pa-1 d-flex justify-end">
        <span class="blue--text" style="cursor: pointer" @click="limit += 7">
          {{ $t("common.viewMore") }}
        </span>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex w-100 justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
  </v-container>
</template>

<script>
import moment from "moment";
import progressService from "@/services/progress";

export default {
  name: "ProgressPrescription",
  data() {
    return {
      totals: "",
      items: [],
      limit: 7,
      loading: false,
    };
  },
  watch: {
    limit() {
      this.renderListMedication();
    },
  },
  created() {
    this.renderListMedication();
  },
  methods: {
    async renderListMedication() {
      this.loading = true;
      var patientID = this.$route.params.patientId;
      const apiResult = await progressService.getMedication(
        patientID,
        1,
        this.limit
      );
      if (apiResult.error) {
        this.loading = false;
        this.$toast.error(apiResult.message);
        return;
      }

      this.loading = false;
      this.totals = apiResult.totals;
      this.items = apiResult.items.map((i) => ({
        date: moment(i.date).format("DD/MM/YYYY"),
        totalsMedications: i.medication.totals + 1,
        data: i.medication.items,
      }));
    },
  },
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  th {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  td {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
}
</style>