<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">{{ $t("main.orthancImage.title") }}</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="renderListImages">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <!-- Display result -->
      <table>
        <thead>
          <tr style="font-size: 18px">
            <th>{{ $t("main.orthancImage.date") }}</th>
            <th>{{ $t("main.orthancImage.imageLink") }}</th>
          </tr>
        </thead>
        <tbody v-if="networkError">
          <tr>
            <td colspan="2">
              <span class="red--text">
                {{ $t("main.orthancImage.alerErr") }}
              </span>
            </td>
          </tr>
        </tbody>
        <tbody v-if="!networkError && !items.length">
          <tr>
            <td colspan="2">{{ $t("main.orthancImage.alerErr") }}</td>
          </tr>
        </tbody>
        <tbody v-else v-for="(item, index) in items" :key="index">
          <tr v-for="(image, index2) in item.images" :key="index2">
            <td :rowspan="item.images.length + 1">
              <a
                @click="openEpisodeRecord(item)"
                class="text-body-1 font-weight-bold"
              >
                {{ formatDate(item.startTime) }}
                <v-icon v-if="item.episodeRecordID" color="blue">
                  mdi-open-in-new
                </v-icon>
              </a>
            </td>
            <td>
              <img
                v-for="n in 4"
                v-show="image.thumbnails[n - 1]"
                :key="'thumbnail' + n"
                class="thumbnail-image"
                :src="image.thumbnails[n - 1]"
                :alt="'image broken'"
                @click="openSlideShow(image.thumbnails, n)"
              />
              <span v-if="image.thumbnails.length - 4 > 0" class="more-text">
                +{{ image.thumbnails.length - 4 }} more
              </span>
              <a @click="openViewer(image)" class="open-link">
                <v-icon color="blue">mdi-open-in-new</v-icon>
                Open Viewer
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
    <template v-else>
      <div
        class="d-flex w-100 justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
    <PopupSlideShow ref="PopupSlideShow" />
  </v-container>
</template>

<script>
import moment from "moment";
import imageService from "@/services/image";
import PopupSlideShow from "@/components/PopupSlideShow";

export default {
  components: {
    PopupSlideShow,
  },
  data() {
    return {
      items: [],
      page: 1,
      limit: 10,
      loading: false,
      networkError: false,
    };
  },
  watch: {
    page() {
      this.renderListImages();
    },
  },
  created() {
    this.renderListImages();
  },
  methods: {
    formatDate(str) {
      if (!str) return "";
      return moment(str).format("DD/MM/YYYY");
    },
    openViewer(image) {
      if (!image.episodeRecordID) {
        return this.openInNewTab(
          imageService.getOsimisViewerUrl(image.orthancStudyID)
        );
      }
      return this.openInNewTab(image.value);
    },
    openInNewTab(href) {
      return window.open(href, "_blank");
    },
    openSlideShow(images, model) {
      this.$refs.PopupSlideShow.open(images, model - 1);
    },
    openEpisodeRecord(item) {
      var { episodeRecordID, startTime } = item;
      if (!episodeRecordID) return;
      var patientID = this.$route.params.patientId;
      var key = `patient/${patientID}/episodeRecord/${episodeRecordID}/chief-complaints`;
      var label = moment(startTime).format("DD/MM/YYYY");
      var tabData = {
        key,
        label,
      };
      parent.postMessage(
        { event: "ADD_EPISODE_RECORD_TAB", data: tabData },
        "*"
      );
    },
    async renderListImages() {
      this.loading = true;
      this.networkError = false;
      var patientID = this.$route.params.patientId;
      const apiResult = await imageService.searchImages(
        patientID,
        "",
        this.page,
        this.limit
      );
      this.loading = false;
      if (!apiResult || apiResult.error) {
        this.networkError = true;
        return;
      }
      var items = apiResult;
      items.forEach((item) => {
        item.images.forEach((image) => {
          image.thumbnails = image.thumbnails.map((thumbnail) =>
            imageService.getStreamUrl(thumbnail)
          );
        });
      });
      this.items = items;
    },
  },
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  th {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  td {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
}
.thumbnail-image {
  height: 54px;
  float: left;
  border-radius: 5px;
  margin-left: 5px;
  border: 3px solid transparent;
  cursor: pointer;
  transition: 0.4s;
}
.thumbnail-image:hover {
  border: 3px solid #1565c0;
}
span.more-text {
  float: left;
  margin-left: 8px;
  margin-top: 13px;
}
a.open-link {
  float: left;
  margin-left: 20px;
  margin-top: 12px;
}
</style>