<template>
  <v-container fluid class="pt-5 pb-8 greyBg">
    <v-row style="margin-top: -45x">
      <v-col
        cols="12"
        lg="6"
        md="6"
        v-for="item in listProgressComponent"
        :key="item"
      >
        <v-card elevation="0" class="pt-2 pb-2">
          <component :is="item" class="fixed-height table" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import IOPChart from "@/components/Dashboard/components/ChartIOP";
import TableIOP from "@/components/Dashboard/components/TableIOP";
import VisualAcuity from "@/components/Dashboard/components/VisualAcuity";
import ProgressDiagnosisTable from "@/components/Dashboard/components/ProgressDiagnosisTable";
import ProgressPrescription from "@/components/Dashboard/components/ProgressPrescription";
import GeneralExamProgress from "@/components/Dashboard/components/GeneralExamProgress";
import OrthancImages from "@/components/Dashboard/components/OrthancImages";

export default {
  created() {},
  name: "Progress",
  components: {
    TableIOP,
    IOPChart,
    VisualAcuity,
    ProgressDiagnosisTable,
    ProgressPrescription,
    GeneralExamProgress,
    OrthancImages,
  },
  data: () => ({
    listProgressComponent: [
      "GeneralExamProgress",
      "OrthancImages",
      "IOPChart",
      "VisualAcuity",
      "ProgressDiagnosisTable",
      "ProgressPrescription",
      "TableIOP",
    ],
  }),
  methods: {},
};
</script>

<style>
.fixed-height {
  height: 70vh;
  min-height: 250px;
  min-width: 200px;
  padding: 15px;
}
.table {
  overflow: auto;
}
</style>