import HttpClient from '../plugins/httpClient'
import { ApiGateway } from '../plugins/gateway'
var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/Image';

export default {
  searchImages: (patientID, episodeRecordID = "", page = 1, limit = 10) => {
    var query = {
      episodeRecordID
    }
    var header = {
      page, limit
    }
    return httpClient.get(`${prefix}/Search/${patientID}`, query, header)
  },
  getStreamUrl(thumbnailUrl) {
    return `${ApiGateway.Emr}${prefix}/Stream?url=${thumbnailUrl}`;
  },
  getOsimisViewerUrl(studyID) {
    return `${ApiGateway.Orthanc}/osimis-viewer/app/index.html?study=${studyID}`
  }
}