<template>
  <v-dialog v-model="isShow" max-width="1360px" eager>
    <v-carousel v-model="model" height="800px">
      <v-carousel-item v-for="(image, i) in images" :key="image">
        <v-sheet tile>
          <v-row align="center" justify="center">
            <div class="display-3">
              <img
                :src="image"
                :alt="'image ' + (i + 1)"
                style="height: 800px"
              />
            </div>
          </v-row>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      images: [],
      model: 0,
      isShow: false,
    };
  },
  methods: {
    open(images = [], model = 0) {
      this.images = images;
      this.model = model;
      this.isShow = true;
    },
  },
};
</script>