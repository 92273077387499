import HttpClient from '../plugins/httpClient'
import AppConstant from '../plugins/constant'
import { ApiGateway } from '../plugins/gateway'

var httpClient = new HttpClient(ApiGateway.Emr);

const prefix = '/Progress';

export default {
    async getRecordChartIOP(patientID, page = 1, limit = 10) {
      var query = {
        patientID
      }
      var header = {
        page,
        limit: limit || AppConstant.DefaultPaginationLimit,
      }
      return httpClient.get(`${prefix}/ChartIOP`, query, header);
    },

    async getVisualAcuity(patientID, page = 1, limit = 10) {
      var query = {
        patientID
      }
      var header = {
        page,
        limit: limit || AppConstant.DefaultPaginationLimit,
      }
      return httpClient.get(`${prefix}/VisualAcuity`, query, header);
    },

    async getDiagnosis(patientID, page = 1, limit = 10) {
      var query = {
        patientID
      }
      var header = {
        page,
        limit: limit || AppConstant.DefaultPaginationLimit,
      }
      return httpClient.get(`${prefix}/Diagnosis`, query, header);
    },

    async getMedication(patientID, page = 1, limit = 10) {
      var query = {
        patientID
      }
      var header = {
        page,
        limit: limit || AppConstant.DefaultPaginationLimit,
      }
      return httpClient.get(`${prefix}/Medication`, query, header);
    },

}