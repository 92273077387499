<template>
  <v-container>
    <v-row justify="space-between">
      <v-col lg="4" md="5" sm="12" sx="12">
        <h3 class="mb-2">{{ $t("main.visualAcuity.title") }}</h3>
      </v-col>
      <v-col lg="1" md="2" sx="12" sm="12">
        <v-btn icon class="float-right" small @click="renderVisualAcuity">
          <v-icon color="black">mdi-reload</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <template v-if="!loading">
      <!-- Render result -->
      <table class="mt-2">
        <thead>
          <tr style="font-size: 18px">
            <th rowspan="2">{{ $t("main.visualAcuity.date") }}</th>
            <th colspan="2">UCVA</th>
            <th colspan="2">BCVA</th>
          </tr>
          <tr>
            <th>{{ $t("main.visualAcuity.right") }}</th>
            <th>{{ $t("main.visualAcuity.left") }}</th>
            <th>{{ $t("main.visualAcuity.right") }}</th>
            <th>{{ $t("main.visualAcuity.left") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!items.length">
            <td colspan="6">{{ $t("main.visualAcuity.noData") }}</td>
          </tr>
          <tr v-for="(item, index) in items" :key="index">
            <td>
              <span class="font-weight-bold text-body-2">{{ item.time }}</span>
            </td>
            <td>{{ item.data.od.sc }}</td>
            <td>{{ item.data.os.sc }}</td>
            <td>{{ item.data.od.cc }}</td>
            <td>{{ item.data.os.cc }}</td>
          </tr>
        </tbody>
      </table>
      <div class="mt-2 pa-1 d-flex justify-end">
        <span class="blue--text" style="cursor: pointer" @click="limit += 7">
          {{ $t("common.viewMore") }}
        </span>
      </div>
    </template>
    <template v-else>
      <div
        class="d-flex w-100 justify-center align-center"
        style="height: 100%"
      >
        <v-progress-circular
          :size="40"
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
    </template>
  </v-container>
</template>

<script>
import progressService from "@/services/progress";
import moment from "moment";

export default {
  name: "VisualAcuity",
  data() {
    return {
      totals: "",
      items: [],
      limit: 7,
      loading: false,
    };
  },
  watch: {
    limit() {
      this.renderVisualAcuity();
    },
  },
  async created() {
    await this.renderVisualAcuity();
  },
  methods: {
    async renderVisualAcuity() {
      var patientID = this.$route.params.patientId;
      this.loading = true;
      const apiResult = await progressService.getVisualAcuity(
        patientID,
        1,
        this.limit
      );
      if (apiResult.error) {
        this.loading = false;
        this.$toast.error(apiResult.message);
        return;
      }
      this.loading = false;
      this.totals = apiResult.totals;
      this.items = apiResult.items.map((i) => ({
        time: moment(i.time).format("DD/MM/YYYY"),
        data: i.data,
      }));
    },
  },
};
</script>

<style lang="scss">
table {
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.6);
  th {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
  td {
    width: auto;
    text-align: center;
    padding: 10px 0;
    border: 1px solid rgba(0, 0, 0, 0.6);
  }
}
</style>